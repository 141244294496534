import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { graphql } from "gatsby"
let Parser = require('rss-parser');
let parser = new Parser();

const CommunityPage = ({ serverData }) => {
  return(
  <Layout>
    <Seo title="Shows" />
    <div className="px-10 py-8 text-white full-width bg-hctv-blue md:px-20 lg:px-24 xl:px-40">
      <div className="flex flex-row flex-shrink-0 gap-6 px-10 overflow-x-auto text-lg font-black uppercase full-width md:px-20 lg:px-24 xl:px-40 hide-scroll flex-nowrap whitespace-nowrap">
        <Link to="/community" activeClassName="active-white">
          Live
        </Link>
      </div>
    </div>
    <div className="embed-container full-width">
      <iframe
        src="https://reflect-city-hudson-oh.cablecast.tv/cablecastapi/live?channel_id=1&use_cdn=true"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title="HCTV Community Ch. 1021"
      ></iframe>
    </div>
    <h1 className="mt-10 text-4xl font-black">HCTV Community Ch. 1021</h1>
    <div className="flex gap-4 mb-6">
      <p className="text-gray-500">Live</p>
    </div>
    <div className="grid gap-6 p-6 mb-32 border rounded-md">
      {serverData.items.map(node => (
        <div className="flex flex-col pb-6 border-b md:items-center md:flex-row">
          <div className="flex items-center gap-2 mr-4">
            <p className="w-[36px] h-[36px] md:w-[46px] md:h-[46px] flex items-center justify-center font-bold bg-gray-100 text-black text-md md:text-xl rounded-full">{new Date(node.isoDate).getDate()}</p>
            <p className="uppercase">{new Date(node.isoDate).toLocaleString('default', { month: 'short' })}, {new Date(node.isoDate).toLocaleString('default', { weekday: 'short' })}</p>
            <p className="font-bold">{new Date(node.isoDate).toLocaleTimeString('en-US', {timeZone: 'UTC', hour12: true, hour:'2-digit', minute: '2-digit'})}</p>
          </div>
          <p className="pt-4 pl-1 md:pl-6 md:text-xl md:pt-0">{node.title}</p>
        </div>
      ))}
    </div>
  </Layout>
  )
}

export default CommunityPage

export async function getServerData() {
  let feed = await parser.parseURL('https://hctv-vio.hudson.oh.us/Cablecast/Plugins/RSSSchedule/rss.aspx?Days=7&ChannelID=1&localizetime=true');

  return {
    props: feed,
  }
}